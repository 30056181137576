// @mui
import { Stack, Button, Typography } from '@mui/material';
// assets
import { DocIllustration } from '../../../assets';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  return (
    <Stack
      spacing={3}
      sx={{ px: 5, pb: 4, mt: 4, width: 1, justifyContent: 'center', alignItems: 'center', textAlign: 'center', display: 'flex' }}
    >
      <DocIllustration sx={{ width: 0.8 }} />
      <Button variant="contained">Contact</Button>
    </Stack>
  );
}
