// MUI
import ButtonBase from '@mui/material/ButtonBase';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
// Context
import { Wallet } from '../contexts/UserContext';

// const RootStyle = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(2, 2.5),
//   borderRadius: Number(theme.shape.borderRadius) * 1.5,
//   backgroundColor: theme.palette.grey[500_12],
//   transition: theme.transitions.create('opacity', {
//     duration: theme.transitions.duration.shorter,
//   }),
// }));

type Props = {
  wallet: null | Wallet;
  connectWallet: null | VoidFunction;
  openWallet: null | VoidFunction;
  isCollapse?: boolean;
};

export default ({ wallet, connectWallet, openWallet }: Props): React.ReactElement => {
  const empty = () => {};
  const onClick = (wallet?.address ? openWallet : connectWallet) || empty;
  return (
    <ButtonBase
      focusRipple={true}
      // variant="contained"
      onClick={onClick}
      disabled={Boolean(!wallet?.isInstalled)}
    >
      <Paper
        elevation={wallet?.address ? 2 : 8} sx={{ p: 3, pl: 8, pr: 8, width: '100%' }}
        style={{ backgroundColor: '#FED045', }}
      >
        <Typography variant="subtitle2">
          {
            wallet?.address ?
              'Connected':
              'Connect Web3 Wallet'
          }
        </Typography>
        {wallet?.balance &&
          <Typography>
            {(wallet?.parsedBalance || 0).toFixed(5).slice(0,-1)} AVAX
          </Typography>
        }
      </Paper>
    </ButtonBase>
  );
}
