import { Suspense, lazy, ElementType, useCallback, useEffect, useRef, useState, Fragment } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// Libs
import { ethers, BigNumber } from 'ethers';
import { useSnackbar } from 'notistack';
// Layouts
import { useTheme } from '@mui/material/styles';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// Context
import UserContext, { Network, Wallet } from '../contexts/UserContext';
// Components
import LoadingScreen from '../components/LoadingScreen';
import WalletButton from '../components/WalletButton';

// ----------------------------------------------------------------------
// Declare ethereum provider to the global window scope.
declare global {
  interface Window {
    ethereum: ethers.providers.ExternalProvider;
  }
}

// ----------------------------------------------------------------------
// First router who validate if user's metamask is connected
// to the application.
export default (): React.ReactElement => {
  const [wallet, setWallet] = useState<null|Wallet>(null);
  const prevBalanceRef = useRef(-1);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const fetchWallet = useCallback(async () => {
    console.log('fetchWallet');
    const wallet: Wallet = {
      address: null,
      network: null,
      balance: null,
      parsedBalance: null,
      isInstalled: false,
    };

    if (window.ethereum) {
      // If the provider is detected then wallet is installed.
      wallet.isInstalled = true;
      // Fetch more information about the wallet and the network.
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      let network = null;
      let address = null;
      try {
        network = await provider.detectNetwork();
        address = await signer.getAddress();
      } catch (error) {
        // console.error('Please login to your wallet', error);
      }
      // Update the temporary wallet instance.
      if (network) {
        wallet.network = network;
      }
      if (address) {
        wallet.address = address;
      }
      if (address) {
        wallet.balance = await provider.getBalance(address);
        wallet.parsedBalance = parseFloat(ethers.utils.formatEther(wallet.balance))
      }
      // Only change the wallet when the balance state if it differs from previous one.
      if (prevBalanceRef.current !== wallet.parsedBalance) {
        prevBalanceRef.current = wallet.parsedBalance || 0;
        // console.log('Update wallet', wallet);
        setWallet(wallet);
      }
    }
  }, []);

  const connectWallet = async () => {
    // Check if MetaMask is installed on user's browser
    if(window.ethereum && window.ethereum.request) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        // const account = accounts[0];
        // console.log('accounts', accounts);
      } catch (error) {
        // Already processing eth_requestAccounts. Please wait.
        if (error.code === -32002) {
          enqueueSnackbar('Already sent request. Please sign in to your browser wallet.', {
            variant: 'warning',
          });
        }
        console.log('error', error);
      }
    } else {
      alert("Please install Mask");
    }
  }

  const openWallet = (): void => {
    console.log('open wallet');
  }

  useEffect(() => {
    // TODO this must be the same object...
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      // the fetchWallet function will be called at least once.
      provider.on('block', fetchWallet);
      provider.on('accountsChanged', fetchWallet);
      provider.on('networkChanged', fetchWallet);
      return () => {
        provider.off('block', fetchWallet);
        provider.off('accountsChanged', fetchWallet);
        provider.off('networkChanged', fetchWallet);
      };
    }
  }, [fetchWallet]);
//wallet?.address
  if(true) {
    // If wallet is activated return wallet.
    return <Fragment>
      <UserContext.Provider
          value={{
            wallet: wallet,
            connectWallet: connectWallet,
            openWallet: openWallet,
            clearContext: () => {
              setWallet(null);
            }
          }}
        >
      {
        useRoutes([
          {
            path: '/',
            // element: <Navigate to="/dashboard/view" replace />,
            element: <LandingPage />,
          },
          {
            path: '/dashboard',
            element: <DashboardLayout />,
            children: [
              //{ element: <Navigate to="/dashboard/gold" replace />, index: true },
              { path: 'view', element: <Dashboard /> },
              {
                path: 'projects',
                element: <Projects />,
                children: [
                  // { path: '/product01', element: <Gold /> }
                ],
              },
              { path: 'project/project01', element: <Gold /> },
              // { path: 'diamonds', element: <Diamonds /> },
              // { path: 'diamond', element: <Diamond /> },
              // { path: 'gold', element: <Gold /> },
              // { path: 'diamonds', element: <Diamonds /> },
              { path: 'portfolio', element: <Market /> },
            ],
          },
          {
            path: '*',
            element: <LogoOnlyLayout />,
            children: [
              { path: '404', element: <NotFound /> },
              { path: '*', element: <Navigate to="/404" replace /> },
            ],
          },
          { path: '*', element: <Navigate to="/404" replace /> },
        ])
      }
      </UserContext.Provider>
    </Fragment>
  }
  // return (
  //   <Grid container={true} spacing={0} style={{ height: '100%' }}>
  //     <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  backgroundColor: theme.palette.grey[800] }}>
  //       <div>
  //         <WalletButton
  //           wallet={wallet}
  //           connectWallet={connectWallet}
  //           openWallet={openWallet}
  //         />
  //         <div>
  //           {!wallet?.isInstalled && <div>
  //             You can install wallet here.
  //           </div>}
  //         </div>
  //       </div>
  //     </Grid>
  //     <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: theme.palette.background.neutral, color: theme.palette.primary.darker }}>
  //       Nice animation about crypto bets.
  //     </Grid>
  //   </Grid>
  // );
}

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

// Pages.
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));

const Projects = Loadable(lazy(() => import('../pages/Projects')));
const Gold = Loadable(lazy(() => import('../pages/Gold')));

const Diamonds = Loadable(lazy(() => import('../pages/Diamonds')));
const Diamond = Loadable(lazy(() => import('../pages/Diamond')));
const Market = Loadable(lazy(() => import('../pages/Portfolio')));
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
//
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
