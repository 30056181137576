import { SnackbarProvider } from 'notistack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
// import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import ThemeColorPresets from './components/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <RtlLayout>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MotionLazyContainer>
              <ProgressBarStyle />
              {/* <Settings /> */}
              <ScrollToTop />
              <SnackbarProvider autoHideDuration={5500} maxSnack={3}>
                <Router />
              </SnackbarProvider>
            </MotionLazyContainer>
          </LocalizationProvider>
        </RtlLayout>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
