/**
 * User Context
 *
 * The React Context for Employer Portal.
 * @link https://reactjs.org/docs/context.html
 */
import { createContext } from 'react';
import { BigNumber } from 'ethers';

export type Network = {
  chainId: number;
  name: null | string;
};

export interface Wallet {
  address: null | string;
  network: null | Network;
  balance: null | BigNumber;
  parsedBalance: null | number;
  isInstalled: boolean;
}

export interface UserContextType {
  wallet: null | Wallet;
  connectWallet: null | VoidFunction;
  openWallet: null | VoidFunction;
  clearContext: null | VoidFunction;
}

const UserContext = createContext<UserContextType>({
  wallet: null,
  connectWallet: null,
  openWallet: null,
  clearContext: null,
});
export default UserContext;
