import { useContext } from 'react';
// Context
import UserContext, { UserContextType } from '../../../contexts/UserContext';
// Components
import WalletButton from '../../../components/WalletButton';

type Props = {
  isCollapse: boolean | undefined;
};

export default function NavbarAccount({ isCollapse }: Props) {
  const { wallet, connectWallet, openWallet } = useContext<UserContextType>(UserContext);
  return (
    <WalletButton
      wallet={wallet}
      connectWallet={connectWallet}
      openWallet={openWallet}
      isCollapse={isCollapse}
    />
  );
}
