// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  kanban: getIcon('ic_kanban'),
  marketplace: getIcon('ic_marketplace'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'MENU',
    items: [
      { title: 'Dashboard', path: '/dashboard/view', icon: ICONS.marketplace },
      { title: 'Projects', path: '/dashboard/projects', icon: ICONS.dashboard },
      { title: 'My Portfolio', path: '/dashboard/portfolio', icon: ICONS.analytics },
      // { title: 'Diamond Marketplace', path: '/dashboard/diamonds', icon: ICONS.diamond },
      // { title: 'Diamonds', path: '/dashboard/diamonds', icon: ICONS.diamond },
      // { title: 'Reports & Analytics', path: '/dashboard/reports', icon: ICONS.kanban },
    ],
  },
];

export default navConfig;
